import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import moment from "moment"
import { Link } from "gatsby"

const MicroBlog = ({ content }) => {
  if (!content) return <span></span>

  const {
    body,
    frontmatter: { title, date },
  } = content

  const slug = "/microblog/" + moment(date).format("YYYYMMDDHHMMSS")

  return (
    <div className="microblog">
      <div>
        <a to={slug} className="title">
          {title}
        </a>
        <div className="body">
          <MDXRenderer>{body}</MDXRenderer>
        </div>
      </div>
      <div className="footer">
        <a href={slug} className="time">
          {moment(date).fromNow()}
        </a>
      </div>
    </div>
  )
}

export default MicroBlog
