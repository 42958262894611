import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import MicroblogElement from "../components/microblog"

const Microblog = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { type: { eq: "microblog" } } }
      ) {
        nodes {
          frontmatter {
            date
            title
            slug
            type
          }
          body
          slug
          id
        }
      }
    }
  `)

  // Array of all news articles
  const allNews = data.allMdx.nodes

  // State for the list
  const [list, setList] = useState([...allNews.slice(0, 20)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allNews.length > 20)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allNews.length
      const nextResults = isMore
        ? allNews.slice(currentLength, currentLength + 10)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allNews.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <Layout wide>
      <div className="microblog-page">
        <div className="header">
          <b>Welcome to my experimental microblog</b>
          <div className="sub">
            I use it to collect short-form notes, snippets and media.
          </div>
        </div>
        <div className="blogs">
          {list.map(content => (
            <MicroblogElement key={content.id} content={content} />
          ))}
        </div>
        {hasMore ? (
          <button className="read-more" onClick={handleLoadMore}>
            Some more <span>↓</span>
          </button>
        ) : null}
      </div>
    </Layout>
  )
}

export default Microblog
